// 查看更多的排版
<template>
    <div>
        <div class="main font-color-wirte">
            <div class="left">
                <div class="left-center">
                    <div class="title">微课专享</div>
                    <div class="loding-list" v-for="it in [1,2,3,4]">
                        <div class="name">书画鉴赏</div>
                    </div>
                </div>
            </div>
            <div class="right">
               <div class="right-center"  v-for="item in [1, 2, 3, 4]">
                <div class="video-image">
                    <img src="https://img0.baidu.com/it/u=422078137,1307526884&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800"
                        alt="">
                </div>
                <div class="text">龙凤花鸟字技法</div>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: ''
        }
    },

    created() {
        // this.title = this.$route.params.title
        // console.log(this.title);
    }
}
</script>

<style lang="less" scoped>
@import './css/seemore.less';
</style>